import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from '../utils/footer';
import { Webservice } from '../services/index';
import { Link } from 'react-router-dom';
type FormElem = React.FormEvent<HTMLFormElement>;

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  statusLabel: {
    color: '#ff1234'
  },
  logout: {
    display: 'block',
    textAlign: 'center'
  }
}));

function Redeem() {
  const classes = useStyles();
  const [code, setCode] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [showRedeem, setShowRedeem] = useState<boolean>(false);

  useEffect(() => {
    console.log('Use Effect');
  });

  const redeemCode = async () => {
    await Webservice.updateCode(id);
    setShowRedeem(false);
    setStatus('');
    setCode('');
  };

  const handleSubmit = async (e: FormElem) => {
    e.preventDefault();

    setStatus('');
    const codeData: any = await Webservice.redeemCode({
      code: code
    });
    const status = codeData.data.status;
    switch (status) {
      case true:
        setStatus('');
        setShowRedeem(true);
        setId(codeData.data._id);
        break;
      case 'redeem':
        setStatus('Code Redeemed');
        setShowRedeem(false);
        break;
      case 'empty':
        setStatus('Not Found');
        setShowRedeem(false);
        break;
      default:
        break;
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Redeem
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            placeholder="GOTITA-"
            fullWidth
            id="code"
            label="Código"
            name="code"
            value={code}
            onChange={e => setCode(e.target.value)}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Verify code
          </Button>
          {showRedeem ? (
            <Box component="span" m={0}>
              Do yo want redeem?
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={redeemCode}
              >
                Redeem
              </Button>
            </Box>
          ) : (
            ''
          )}
        </form>
        <Box component="span" m={1} className={classes.statusLabel}>
          {status}
        </Box>
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
      <Box component="span" m={1} className={classes.logout}>
        <Link to="/">Logout</Link>
      </Box>
    </Container>
  );
}

export default Redeem;
