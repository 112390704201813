import axios from 'axios';
import { loginUrl, apiUrl } from '../utils/constants';

interface ILogin {
  email: string;
  password: string;
}

interface ICode {
  code: string;
}

const login = async (data: ILogin) => {
  const create = await axios.post(`${loginUrl}`, {
    password: data.password,
    email: data.email
  });
  console.log(create);
  return create;
};

const redeemCode = async (data: ICode) => {
  console.log('verify code');
  const create = await axios.post(`${apiUrl}/codes/checkCoupon`, {
    code: data.code
  });
  console.log(create);
  return create;
};

const updateCode = async (id: string) => {
  const create = await axios.post(
    `${apiUrl}/codes/redeemCoupon`,
    {
      id: id
    },
    getOptions()
  );
  console.log(create);
  return create;
};

function getOptions() {
  let options: any = {};
  if (localStorage.getItem('token')) {
    options.headers = {
      'x-access-token': localStorage.getItem('token')
    };
  }
  return options;
}

export { login, redeemCode, updateCode };
