import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './containers/index';
import Redeem from './containers/redeem';
import './App.css';

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/redeem">{<Redeem />}</Route>
        <Route path="/">{<Login />}</Route>
      </Switch>
    </Router>
  );
};

export default App;
